"use client"

import React, { useEffect } from "react"
import Logo from "@/icons/Logo"
import { H2Med } from "@/styles/Type"
import clsx from "clsx"
import { useChatContext } from "stream-chat-react"
import { StreamTypes } from "@/lib/chatClient"
import { Event } from "stream-chat"

interface DeviceLoginClientProps {
  deviceData: { user_id: string; ott: string }
  onLoginSuccess: (e: any, isAddDevice: boolean) => void
  onLoginRejected: () => void
}
const DeviceLoginClient = React.memo(
  ({ onLoginSuccess, deviceData, onLoginRejected }: DeviceLoginClientProps) => {
    const { client } = useChatContext<StreamTypes>()

    useEffect(() => {
      const handleChatEvent = async (event: Event<StreamTypes>) => {
        if (
          event.channel_id === `${deviceData?.user_id}_system_message_channel`
        ) {
          const parsedMessage = JSON.parse(event.message.text)
          if (
            parsedMessage?.payload?.content?.server_event ===
            "LOGIN_APPROVED_FROM_MOBILE"
          ) {
            onLoginSuccess({ preventDefault: () => {} }, true)
          }
          if (
            parsedMessage?.payload?.content?.server_event ===
            "LOGIN_REJECTED_FROM_MOBILE"
          ) {
            onLoginRejected()
          }
        }
      }
      client.on("notification.message_new", handleChatEvent)
      return () => {
        client.off("notification.message_new", handleChatEvent)
      }
    }, [client, onLoginSuccess, deviceData?.user_id, onLoginRejected])

    if (!deviceData?.user_id) {
      return null
    }

    return (
      <div className="h-screen w-full flex items-center flex-col justify-between text-white/30 text-2xl">
        <video
          className={clsx("fixed inset-0 h-full w-full object-cover")}
          src="/assets/videos/bg_video.mp4"
          autoPlay
          playsInline
          loop
          muted
        />
        <div className="mr-auto ml-5 mt-5">
          <Logo className="relative" />
        </div>
        <div className="flex w-full items-center justify-center">
          <div className="flex flex-col w-[320px] text-center">
            <H2Med className="relative text-cream mb-3">Almost done!</H2Med>
            <H2Med className="relative mb-5">
              Confirm login with your iOS device...
            </H2Med>
          </div>
        </div>
        <div className="flex w-full flex-col gap-[12px] mb-10">
          <H2Med className="relative text-center !text-[14px]">
            Continue the login process on your iOS device
          </H2Med>
          <H2Med className="relative text-center !text-[14px] text-white/50">
            Don’t have and iOS device? Contact us
          </H2Med>
        </div>
      </div>
    )
  }
)

DeviceLoginClient.displayName = "DeviceLoginClient"

export default DeviceLoginClient
